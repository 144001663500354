import { Layout, Row, Col, Typography } from "antd";
import Navbar from "../Navbar/Presentational";
import Footer from "../Footer/Presentational";
import Copy from "../shared/Copy/Presentational";

export default function Impressum() {
  return (
    <Layout className="shared-layout">
      <Row>
        <Col className="shared-col-2" span={24}>
          <Row type="flex" justify="center">
            <Col span={20}>
              <Navbar />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col span={24}>
          <Row type="flex" justify="center">
            <Col span={20}>
              <div className="impressum-wrapper">
                <h1 className="l1-title2">Impressum</h1>
                <br />
                <br />
                <Row>
                  <h3 className="l3-title2">
                    LEVA is operated by Leva Capital Partners AG
                  </h3>
                  <br />
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="venture-mb"
                  >
                    <h3 className="l4-title2">Leva Capital Partners AG</h3>
                    <h3 className="l4-title2">Bernapark 1</h3>
                    <h3 className="l4-title2">3066 Stettlen </h3>
                    <h3 className="l4-title2">Bern </h3>
                    <h3 className="l4-title2">CHE-393.615.656</h3>
                    <h3 className="l4-title2">
                      {" "}
                      <a href="mailto:support@levamail.com">
                        support@levamail.com
                      </a>
                    </h3>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                    <h3 className="l4-title2">Leva Capital Partners AG</h3>
                    <h3 className="l4-title2">Langstrasse 175</h3>
                    <h3 className="l4-title2">8005 Zürich </h3>
                    <h3 className="l4-title2">Zürich </h3>
                    <h3 className="l4-title2">CHE-393.615.656</h3>
                    <h3 className="l4-title2">
                      <a href="mailto:support@levamail.com">
                        support@levamail.com
                      </a>
                    </h3>
                  </Col>
                </Row>
                <br />
                <br />
                <Row>
                  <h3 className="l3-title2">Media contact</h3>
                  <br />
                  <Col
                    xs={24}
                    sm={24}
                    md={24}
                    lg={12}
                    xl={12}
                    className="venture-mb"
                  >
                    <h3 className="l4-title2">Aisling Ryan</h3>
                    <h3 className="l4-title2">
                      Email:{" "}
                      <a href="mailto:aisling.ryan@levamail.com">
                        aisling.ryan@levamail.com
                      </a>
                    </h3>
                    <h3 className="l4-title2">
                      Phone: <a href="tel:+41772772879">+41 77 277 28 79</a>
                    </h3>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      {/*<hr className="impressum-hr" />*/}
      <Row type="flex" justify="center" className="footer">
        <Footer />
        <Copy />
      </Row>
    </Layout>
  );
}
